$(function () {

    //вызов главного слайдера
    if ($(".swiper-wrapper").length) {

        var swiper = new Swiper('.main_slider', {
            simulateTouch: false,
            scrollbar: {
                el: document.querySelector('.swiper-scrollbar'),
                hide: false,
                draggable: true
            },
            navigation: {
                nextEl: '.swiper-button--next',
                prevEl: '.swiper-button--prev'
            },
            //меняем высоту кнопок под высоту контента слайда
            on: {
                'init': function () {
                    var currentHeight = $(this.slides[this.activeIndex]).find(".main_slider__slide_content").outerHeight();
                    $(".swiper-button span").css({"height": currentHeight});
                },
                'slideChange': function () {
                    var currentHeight = $(this.slides[this.activeIndex]).find(".main_slider__slide_content").outerHeight();
                    $(".swiper-button span").css({"height": currentHeight});
                }
            }
        });

    }

    // Плавный скроллинг до якоря
    var documentIsScrolled = false;
    $(".js-slide_to").on("click", function (e) {
        var scrollSize = $($(this).attr("href")).offset().top,
            diff = $(window).scrollTop() - scrollSize;

        if (!documentIsScrolled) {

            documentIsScrolled = true;
            if (diff > 20 || diff < -20) {

                $("body, html").animate({
                    scrollTop: scrollSize - 60
                }, 400, function () {
                    documentIsScrolled = false;
                });
            } else {
                documentIsScrolled = false;
            }
        }

        e.preventDefault();
    });

    //события при скролле
    $(window).scroll(function () {
        if ($(document).width() > 767) {
            if ($(window).scrollTop() > 1050) {
                $('.btn--top').addClass("visible");
            } else {
                $('.btn--top').removeClass("visible");
            }
        }
    });

    function sticky_header() {
        if ($(window).scrollTop() > 50) {
            $('.header_wrapper').addClass("header_wrapper--sticky");
        } else {
            $('.header_wrapper').removeClass("header_wrapper--sticky");
        }
    }

    //допиливаем шапку, чтобы после перезагрузки страницы, если шапка не в самом верху, то она сразу принимает вид, как и при событии скролла
    $(window).scroll(function () {
        sticky_header();
    });

    sticky_header();

    var $header_wrapper = $('.header_wrapper'),
        $window = $(window),
        baseWidth = 0;

    baseWidth = $window.width();

    $(window).on('resize', function () {
        baseWidth = $window.width();

        if (swiper) {
            var currentHeight = $(swiper.slides[swiper.activeIndex]).find(".main_slider__slide_content").outerHeight();
            $(".swiper-button span").css({"height": currentHeight});
        }
    });

    //сдвиг фикс. элементов при открытии ремодала
    $(document).on('opening', '.modal', function () {
        $header_wrapper.css({
            paddingRight: $window.width() - baseWidth + 'px'
        });
        var $modal = $(this).parent();
        if ($modal.children('.modal__close').length == 0) {
            $modal.append('<button data-remodal-action="close" class="modal__close"></button>');
        }
    });

    $(document).on('closed', '.modal', function (e) {
        $header_wrapper.css({paddingRight: ''});
    });

    $('.modal').remodal({
        closeOnOutsideClick: false
    });

    //поля форм с подсказками
    if ($(".form__group--js").length) {

        var $inputs = $("input", ".form__group--js");
        var $textareas = $("textarea", ".form__group--js");

        var fn = function () {
            if ($(this).val().trim() !== "") {
                $(this).closest(".form__group").addClass("form__group--filled");
            }

            $(this).on("focus", function () {
                $(this).closest(".form__group").addClass("form__group--filled");
            });

            $(this).on("blur", function () {
                if ($(this).val().trim() === "")
                    $(this).closest(".form__group").removeClass("form__group--filled");
            });
        };

        $inputs.each(fn);

        $textareas.each(fn);
    }

    //галереи
    var $gallery = $(".gallery");

    if ($gallery.length) {
        $gallery.lightGallery({
            selector: "a"
        });
    }

    var map_container = $('#js-contacts-map');
    if (map_container.length) {
        try {
            var config = JSON.parse(map_container.attr('data-json'));
        } catch (e) {
        }

        if (config) {
            ymaps.ready(function () {
                var myMap = new ymaps.Map('js-contacts-map', {
                    center: [config.lat, config.lng],
                    zoom: config.zoom
                }, {
                    searchControlProvider: 'yandex#search'
                });

                var content = null;
                if (config.title) {
                    content = '<strong>' + config.title + '</strong><br>';
                }
                if (config.content) {
                    content = content ? content : '';
                    content += config.content;
                }
                var mark = new ymaps.Placemark([config.lat, config.lng], {
                    balloonContent: content
                });

                myMap.geoObjects
                    .add(mark);

                myMap.behaviors.disable('scrollZoom');
            });
        }
    }

    var form = document.querySelector('.js-form');
    if (form) {
        form = new TurboForm(form, {
            fieldLineClass: 'form__group',
            messageBoxSelector: '.js-message',
            errorBoxSelector: '.js-message'
        });
    }

});
